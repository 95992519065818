
import _ from 'lodash'
import {getOrderInfo,getOrderByHxId} from "@/api/custom.js"
const Orders = {
    state: {
        // friendList: useLocalStorage('friendList', {}),
        friendList: {},
        // groupList: useLocalStorage('groupList', {}),
        groupList: {},
        // sortedFriendList: useLocalStorage('sortedFriendList', {}),
        sortedFriendList: {},
        friendBlackList: [],

        orderInfo: {}
    },
    mutations: {
        SET_ORDER_INFO: (state, payload) => {
            state.orderInfo = payload
        }
    },
    actions: {
        // 获取订单信息
        getOrderInfo: async ({ commit },groupId) => {
          console.log("%c Line:24 🥃获取订单信息 groupId", "color:#465975", groupId);
            try {
                const { data } = await getOrderInfo(groupId)
                console.log("%c Line:27 🧀拿到订单数据 data", "color:#e41a6a", data);
                if(data){
                  if(data.img){
                    let imgs = JSON.parse(data.img)
                    console.log("%c Line:31 🍇 imgs", "color:#6ec1c2", imgs);
                    if(imgs&&imgs.length){
                      data.titleImg = imgs[0].url
                    }
                  }
                  commit('SET_ORDER_INFO', data)
                }else{
                  commit('SET_ORDER_INFO', {})
                }
                
            } catch (error) {
              console.log("%c Line:30 🍎 error", "color:#6ec1c2", error);
              commit('SET_ORDER_INFO', {})
            }
        },
        // 单聊获取订单信息
        getOrderInfoByPerson: async ({ commit },userId) => {
          console.log("%c Line:24 单聊获取订单信息 userId", "color:#465975", userId);
            try {
                const { data } = await getOrderByHxId(userId)
                console.log("%c Line:27 🧀拿到订单数据 data", "color:#e41a6a", data);
                if(data){
                  if(data.img){
                    let imgs = JSON.parse(data.img)
                    console.log("%c Line:31 🍇 imgs", "color:#6ec1c2", imgs);
                    if(imgs&&imgs.length){
                      data.titleImg = imgs[0].url
                    }
                  }
                  commit('SET_ORDER_INFO', data)
                }else{
                  commit('SET_ORDER_INFO', {})
                }
                
            } catch (error) {
              console.log("%c Line:30 🍎 error", "color:#6ec1c2", error);
              commit('SET_ORDER_INFO', {})
            }
        }
    }
}

export default Orders
