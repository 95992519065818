/* 会话列表排序 */
import _ from 'lodash'

export default function (conversationList) {
    const newConversationObj = {}
    const toBeUpdateData = _.values(conversationList)
    // console.log("%c Line:7 🍐 toBeUpdateData", "color:#93c0a4", toBeUpdateData);
    const sortedArr = toBeUpdateData.sort(function (a,b) {
      // return b.latestSendTime-a.latestSendTime
        if(b.isTop&&b.isTop==1){
          if(a.isTop&&a.isTop==1){
            return  b.latestSendTime-a.latestSendTime
          }else{
            return 1
          }
        }else if(a.isTop&&a.isTop==1){
          if(b.isTop&&b.isTop==1){
            return  b.latestSendTime-a.latestSendTime
          }else{
            return -1
          }
        }else{
          return b.latestSendTime-a.latestSendTime
        }
    })
    sortedArr.forEach((item) => {
        const key = item.conversationKey
        return (newConversationObj[key] = item)
    })
    
    // console.log("%c Line:24 🌶排序好的会话列表 newConversationObj", "color:#4fff4B", JSON.stringify(newConversationObj));
    return newConversationObj
}
